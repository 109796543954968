@import "scss/lib/index.scss";

.components-button {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.15s ease-in-out 0s;
    transform: translateZ(0);
    box-sizing: border-box;

    @include text-button;
    line-height: 100%;

    padding: 7px 10px;
    border-radius: 4px;

    // size options

    &.large {
        padding: 12px 20px;
        border-radius: 4px;
        font-size: 14px;
    }

    &.small {
        padding: 4px 7px;
        border-radius: 2px;
        font-size: 12px;
    }

    // options
    &.left-margin {
        margin-left: 5px;
    }

    &.right-margin {
        margin-right: 5px;
    }

    &.full-width {
        width: 100%;
    }

    // style options

    &.disabled {
        opacity: 0.25;
        cursor: default;
        pointer-events: none;
    }

    &.ghost {
        border: 1px solid rgba(0, 0, 0, 0);
        background: rgba(255, 255, 255, 0);
        color: $color-neutral-800;
    }

    &.secondary {
        border: 1px solid $color-neutral-500;
        background: rgba(255, 255, 255, 0);
        color: $color-neutral-800;
    }

    &.ghost,
    &.secondary {
        &:hover,
        &:focus {
            box-shadow: 0px 1px 5px rgba(60, 60, 60, 0.2);
            background: $color-neutral-0;
            border: 1px solid #b7b7b7;
        }

        &.active,
        &:active {
            background: $color-neutral-200;
            box-shadow: none;
        }
    }

    &.danger {
        border: 1px solid $color-red-900;
        background: rgba(255, 255, 255, 0);
        color: $color-red-900;
    }

    &.danger {
        &:hover,
        &:focus {
            box-shadow: 0px 1px 5px rgba(60, 60, 60, 0.2);
            background: $color-neutral-0;
            border: 1px solid $color-red-600;
        }

        &.active,
        &:active {
            background: $color-neutral-200;
            box-shadow: none;
        }
    }

    &.primary {
        border: 1px solid $color-purple-900;
        background: $color-purple-900;
        color: $color-neutral-0;

        &:hover,
        &:focus {
            box-shadow: 0px 1px 5px rgba(60, 60, 60, 0.2);
            border: 1px solid $color-purple-800;
            background: $color-purple-800;
        }

        &.active,
        &:active {
            border: 1px solid $color-purple-900;
            background: $color-purple-900;
            box-shadow: none;
        }
    }

    &:hover,
    &:focus {
        transform: translateY(-1px);

        // this disables styling from typography.scss, which underlines all
        // anchor links on hover and focus
        text-decoration: none;
    }

    &.active,
    &:active {
        transform: translateY(0px);
    }

    &:focus {
        outline: 0;
    }
}

.components-button-with-dropdown {
    display: inline-flex;

    .dropdown-button {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        // Add a bit of spacing between the main button and the dropdown button
        // only when the button is a primary button
        &.primary {
            margin-right: 1px;
        }
    }

    .button-dropdown-control {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 100%;

        // We only want to render 1px width line between the buttons.
        // This style avoids to render both the left button border + the right
        // button border.
        &:not(.primary) {
            border-left: 0px;
        }
    }
}
