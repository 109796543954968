@import "scss/lib/index.scss";

.components-overlay {
    max-width: 100%;
    display: inline-block;
}

.components-overlay-portal {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .overlay-content-wrap {
        position: absolute;
    }
}
