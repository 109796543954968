@import "scss/lib/index.scss";

.components-icon {
    &.components-icon-primary {
        color: $color-purple-900;
    }
    &.components-icon-success {
        color: $success-text;
    }
    &.components-icon-warning {
        color: $alert-text;
    }
    &.components-icon-error {
        color: $error-text;
    }
    &.components-icon-disabled {
        color: $color-neutral-700;
    }

    &.right-margin {
        margin-right: 5px;
    }

    &.left-margin {
        margin-left: 5px;
    }
}
